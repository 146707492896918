<template>
  <div style="padding: 0 36px; width: 100%">
    <el-form class="form-container">
      <advanced-toolbar
        @dateRangeChanged="handleDateRangeChanged"
        :content-api="contentAPI"
      />
    </el-form>

    <el-scrollbar :native="true" style="margin-top: 20px;">
      <el-table
        :default-sort="{ prop: 'total_messages', order: 'descending' }"
        :data="biometricUsages"
        style="width: 100%"
        row-key="ac_id"
        v-loading="isLoading"
        class="list-table"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :default-sort="{
                prop: 'ac_name',
                order: 'descending'
              }"
              row-key="task_id"
              :data="props.row.tasks"
            >
              <el-table-column
                sortable
                :label="__('Task')"
                prop="task.task_name"
              >
                <template slot-scope="{ row }">
                  {{ (row.task && row.task.task_name) || __("Deleted task") }}
                </template>
              </el-table-column>
              <el-table-column
                sortable
                :label="__('Current Enrolled')"
                prop="total_enrol"
              />
              <el-table-column
                sortable
                :label="__('Total Verify')"
                prop="total_verify"
              />
              <el-table-column
                sortable
                :label="__('Total Transactions')"
                prop="total_transaction"
              />
              <el-table-column :label="__('Detail')">
                <template slot-scope="scope">
                  <el-button
                    @click="handleDetailedLogClick(scope.row)"
                    v-show="scope.row.task_id !== -1"
                    >{{ __("More") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          :label="__('Account')"
          prop="account.ac_name"
        />
        <el-table-column
          sortable
          :label="__('Current Enrolled')"
          prop="total_enrol"
        />
        <el-table-column
          sortable
          :label="__('Total Verify')"
          prop="total_verify"
        />
        <el-table-column
          sortable
          :label="__('Total Transactions')"
          prop="total_transaction"
        />
        <el-table-column>
          <template #header>
            <el-row v-show="hasBiometricUsages()">
              <el-button
                type="primary"
                icon="el-icon-download"
                @click="getBiometricAcCSV"
                >{{ __("Export Ac") }}</el-button
              >
            </el-row>
          </template>
          <template slot-scope="props">
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="getBiometricTaskCSV(props.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <el-dialog
      v-if="showDetailedLog"
      :visible.sync="showDetailedLog"
      custom-class="editContentItemModal"
      destroy-on-close
      fullscreen
    >
      <detailed-log :task="selectedTask"></detailed-log>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AdvancedToolbar from "@/components/AdvancedToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import DetailedLog from "@/views/analyse/virtual-agent-usage/biometric/pages/DetailedLog";
import FileDownloader from "@/mixins/FileDownloader";
import _ from "lodash";
import BackButtonHandler from "@/components/BackButtonHandler";

export default {
  name: "BiometricsList",
  mixins: [BaseContent, BackButtonHandler, FileDownloader],
  components: {
    AdvancedToolbar,
    DetailedLog
  },
  data() {
    return {
      showDetailedLog: false,
      selectedTask: null
    };
  },

  computed: {
    ...mapState("usages", {
      biometricUsages: state => state.biometricUsages,
      isLoading: state => state.loading
    })
  },

  methods: {
    ...mapActions("usages", {
      contentAPI: "getBiometricUsage",
      setTaskForDetailedLogs: "setTaskForDetailedLogs",
      setDateSearchRange: "setDateSearchRange"
    }),

    backButtonHandler() {
      if (this.showDetailedLog) {
        this.showDetailedLog = false;
        return false;
      }
      return true;
    },
    hasBiometricUsages() {
      return this.biometricUsages.length > 0;
    },
    getBiometricAcCSV() {
      let accounts = this.biometricUsages;
      let csv = "Account ID, Account Name, Current Enrolled, Total Verify\n";
      accounts.forEach(row => {
        csv += row.ac_id + ",";
        csv += row.ac_name + ",";
        csv += row.total_enrol + ",";
        csv += row.total_verify + "\n";
      });
      this.downloadFile(csv, "biometric_account.csv");
    },
    getBiometricTaskCSV(task) {
      let tasks = task.tasks;
      let fileName = "biometric_task_" + task.ac_name + ".csv";
      let csv = "Task ID, Task Name, Current Enrolled, Total Verify\n";
      tasks.forEach(row => {
        csv += row.task_id + ",";
        csv += row.task.task_name + ",";
        csv += row.total_enrol + ",";
        csv += row.total_verify + "\n";
      });
      this.downloadFile(csv, fileName);
    },
    handleDateRangeChanged(val) {
      this.setDateSearchRange(val);
    },
    handleDetailedLogClick(task) {
      this.selectedTask = _.cloneDeep(task);
      this.setTaskForDetailedLogs(this.selectedTask);
      this.showDetailedLog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";

.form-container {
  padding: 20px 0;
}
</style>
